// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BakOtpAPi"];

const variantClassNames = {BakOtpAPi: "framer-v-1ycxuw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "BakOtpAPi", image: jqpIrdXwL = {src: new URL("assets/4096/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href} 241w, ${new URL("assets/1024/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href} 482w, ${new URL("assets/2048/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href} 965w, ${new URL("assets/4096/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href} 1930w, ${new URL("assets/AwsrLpvpNHSQYHYmDwqf4tPc.jpg", import.meta.url).href} 2000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BakOtpAPi", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-O3b7o", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1ycxuw", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BakOtpAPi"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fit", intrinsicHeight: 4243, intrinsicWidth: 2000, pixelHeight: 4243, pixelWidth: 2000, ...toResponsiveImage_194x2gw(jqpIrdXwL)}} className={"framer-19xnpwo"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"Yr8a_Q_6w"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.25)", filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-O3b7o [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-O3b7o * { box-sizing: border-box; }", ".framer-O3b7o .framer-qtkesy { display: block; }", ".framer-O3b7o .framer-1ycxuw { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1140px; }", ".framer-O3b7o .framer-19xnpwo { aspect-ratio: 0.4714098646879092 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 2291px); overflow: hidden; position: relative; width: 100%; will-change: transform; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-O3b7o .framer-1ycxuw { gap: 0px; } .framer-O3b7o .framer-1ycxuw > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-O3b7o .framer-1ycxuw > :first-child { margin-top: 0px; } .framer-O3b7o .framer-1ycxuw > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2351
 * @framerIntrinsicWidth 1140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"jqpIrdXwL":"image"}
 */
const FramerUjvzY6KJL: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerUjvzY6KJL;

FramerUjvzY6KJL.displayName = "Spamed beauty image 2";

FramerUjvzY6KJL.defaultProps = {height: 2351, width: 1140};

addPropertyControls(FramerUjvzY6KJL, {jqpIrdXwL: {__defaultAssetReference: "data:framer/asset-reference,AwsrLpvpNHSQYHYmDwqf4tPc.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerUjvzY6KJL, [])